import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import slugify from "slugify";
import { css } from "styled-components/macro";

import { Container, ContentWithPaddingXl } from "../LayoutHelpers";
import { SectionHeading } from "../Headings";
import { PrimaryButton } from "../Buttons";
import Layout from "../Layout";
import { PageProps } from "gatsby";
import { HotelType, JobType } from "../../Common";
import { PageHeaderWithImage } from "../PageHeaderWithImage";
import { GenerateJobSlug } from "../../Common/Functions/GenerateSlugs";
import { renderRichText } from "gatsby-source-storyblok";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div<{ featured: boolean }>`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row! h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
    `}
`;
const Post = styled.div`
  ${tw`cursor-pointer flex flex-col bg-gray-100 rounded-lg`}
`;
const Image = styled.div<{ imageSrc: string }>`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = styled.div`
  ${tw`p-8 border-2 border-t-0 rounded-lg rounded-t-none`}
`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900 group-hover:text-primary-500 transition duration-300`;
const Description = styled.span`${tw`whitespace-pre-wrap`}`;

const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;

export const HotelJobs: React.FC<
  PageProps<any, { headingText: string; jobs: JobType[]; hotel: HotelType }>
> = ({ pageContext: { headingText, jobs, hotel } }) => {
  const [visible, setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible((v) => v + 6);
  };

  return (
    <Layout>
      <Container>
        {hotel && (
          <PageHeaderWithImage
            heading={hotel.name}
            imageSrc={hotel.image.filename}
            subHeading={hotel.city}
          />
        )}
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Posts>
            {jobs.slice(0, visible).map((job, index) => {
              console.log(job.description);
              return (
                <PostContainer key={index} featured={index === 0}>
                  <Post
                    className="group"
                    as="a"
                    href={`/${GenerateJobSlug(job.hotel.name, job.name)}`}
                  >
                    <Image imageSrc={job.image.filename} />
                    <Info>
                      <Category>{job.start_date || "Ab Sofort"}</Category>
                      {/* <CreationDate>{hotel.city}</CreationDate> */}
                      <Title>{job.name}</Title>
                      {index === 0 && job.description && (
                        <Description>{job.description}</Description>
                      )}
                    </Info>
                  </Post>
                </PostContainer>
              );
            })}
          </Posts>
          {visible < jobs.length && (
            <ButtonContainer>
              <LoadMoreButton onClick={onLoadMoreClick}>
                Mehr anzeigen
              </LoadMoreButton>
            </ButtonContainer>
          )}
        </ContentWithPaddingXl>
      </Container>
    </Layout>
  );
};

export default HotelJobs;
